@media only screen and (max-width: 767px) {
  .headding {
    margin-top: 50px;
  }

  .pt-100 {
    padding-top: 60px;
  }

  .pb-100 {
    padding-bottom: 73px;
  }

  .pb-70 {
    padding-bottom: 30px;
  }

  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  p {
    font-size: 13px;
  }

  .banner-three-img {
    padding-right: 0;
  }

  .banner-three-img.banner-three-img20 {
    padding-left: 0;
    padding-right: 0;
  }

  .section-title {
    margin-bottom: 25px;
  }

  .section-title.left-title h2 {
    margin-bottom: 10px;
  }

  .section-title .top-title {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .section-title h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .default-btn {
    font-size: 13px;
    padding: 12.5px 17px;
  }

  .default-btn i {
    margin-left: 0;
    top: 3px;
    font-size: 15px;
  }

  .error-content h1 {
    font-size: 90px;
    margin-bottom: 15px;
  }

  .error-content h2 {
    font-size: 20px;
  }

  .hero-area-two .container-fluid {
    max-width: 540px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .banner-three-area {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .banner-three-area .col-lg-6:nth-child(1) {
    order: 1;
  }

  .banner-three-area .col-lg-6:nth-child(2) {
    order: 2;
  }

  .banner-three-area .col-lg-12 .banner-input-fill .col-lg-3:nth-child(1) {
    order: unset;
  }

  .banner-three-area .col-lg-12 .banner-input-fill .col-lg-3:nth-child(2) {
    order: unset;
  }

  .blog-area .blog-shape {
    display: none;
  }

  .single-blog-details-content h2 {
    font-size: 23px;
    margin-bottom: 15px;
  }

  .single-blog-details-content h3 {
    font-size: 23px;
  }

  .single-blog-details-content p {
    margin-bottom: 15px;
  }

  .single-blog-details-content .blog-details-list {
    margin-bottom: 15px;
  }

  .single-blog-details-content .blog-details-list li {
    font-size: 13px;
    padding-left: 20px;
  }

  .single-blog-details-content .blog-details-list li a {
    font-size: 13px;
  }

  .single-blog-details-content .blog-details-list li img {
    max-width: 12px;
  }

  .single-blog-details-content .event-details-card {
    padding: 20px;
  }

  .single-blog-details-content .event-details-card img {
    position: unset;
    transform: translateY(0);
    margin-bottom: 15px;
    max-width: 30px;
  }

  .single-blog-details-content .blog-details-comment {
    padding: 18px;
  }

  .single-blog-details-content .blog-details-comment .comment-text {
    margin-bottom: 15px;
  }

  .single-blog-details-content .blog-details-comment .comment-text .client-name h4 {
    font-size: 16px;
  }

  .single-blog-details-content .blog-details-comment p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .single-blog-details-content .blog-details-comment .reply-btn {
    text-align: left;
  }

  .single-blog-details-content .leave-comment {
    margin-bottom: 30px;
  }

  .single-blog-details-content .leave-comment h3 {
    font-size: 25px;
  }

  .single-blog-details-content .d-flex {
    display: block !important;
  }

  .single-blog-details-content .d-flex .popular-tags {
    margin-bottom: 20px;
  }

  .single-blog-details-content .d-flex .popular-tags ul li {
    margin-right: 5px;
  }

  .single-blog-details-content .d-flex .popular-tags ul li span {
    font-size: 16px;
    margin-right: 5px;
  }

  .single-blog-details-content .d-flex .popular-tags ul li a {
    padding: 5px;
  }

  .single-blog-details-content .d-flex .blog-sewile-list ul li span {
    font-size: 16px;
    margin-right: 0;
  }

  .single-banner-three-content {
    margin-top: 0 !important;
  }

  .single-banner-three-content p {
    font-size: 13px;
  }

  .single-banner-three-content .banner-input-fill .col-lg-3 {
    padding-right: 10px !important;
  }

  .single-banner-three-content .banner-input-fill .form-select {
    margin-bottom: 20px;
    padding: 10px;
  }

  .single-banner-three-content .banner-select img {
    left: 0 !important;
    margin-left: auto !important;
    margin-right: unset !important;
  }

  .single-contact-card {
    padding: 20px;
    margin-top: 30px;
  }

  .single-contact-card .contact-box ul li a {
    font-size: 13px;
  }

  .contact-form-content {
    margin-right: 0;
  }

  .contact-form-content .form-group .form-control {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-control::-moz-placeholder {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-control:-ms-input-placeholder {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-control::placeholder {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-check .form-check-label {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-check .form-check-label a {
    font-size: 13px;
  }
  .logo-light {
    display: none;
  }

  .privacy-text h3 {
    font-size: 20px;
    line-height: 33px;
  }

  .others-option-vg {
    display: none !important;
  }

  .navbar-area {
    background-color: black;
    padding: 45px 0px;
  }

  .navbar-area.is-sticky {
    background-color: black;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
  }

  .navbar-nav {
    max-height: 50vh;
    overflow-y: scroll;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }

  .navbar-nav::-webkit-scrollbar {
    width: 3px;
  }

  .navbar-nav::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .navbar-nav::-webkit-scrollbar-thumb {
    background: #888;
  }

  .navbar-nav::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .others-option-for-responsive .container .container {
    top: 45px;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
    padding: 20px;
    border-radius: 10px;
    z-index: 99;
  }

  .others-option-for-responsive .others-option {
    display: block !important;
    text-align: center;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 15.5px 25px;
    border: none;
    outline: 0;
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input::-moz-placeholder {
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:-ms-input-placeholder {
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input::placeholder {
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:focus::-moz-placeholder {
    color: transparent;
    -moz-transition: var(--transition);
    transition: var(--transition);
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:focus:-ms-input-placeholder {
    color: transparent;
    -ms-transition: var(--transition);
    transition: var(--transition);
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:focus::placeholder {
    color: transparent;
    transition: var(--transition);
  }

  .others-option-for-responsive .others-option .option-item .search-button {
    position: absolute;
    right: 20px;
    bottom: 0;
    top: 19px;
    border: none;
    background-color: var(--optionalColor);
    width: 50px;
    height: 55px;
    color: var(--whiteColor);
    line-height: 55px;
    padding: 0 !important;
    border-radius: 5px;
    z-index: 1;
  }

  .others-option-for-responsive .others-option .option-item .search-button i {
    font-size: 20px;
    position: relative;
    top: 3px;
  }

  .others-option-for-responsive .others-option .option-item .search-button:hover::after {
    width: 50%;
  }

  .others-option-for-responsive .others-option .option-item .search-button:hover::before {
    width: 50%;
  }

  .others-option-for-responsive .others-option .option-item .default-btn {
    margin-top: 20px;
  }

  .mean-container .mean-nav {
    margin-top: 44px;
  }

  .mean-container .mean-nav ul li a.mean-expand {
    margin-top: 0;
    width: 92%;
  }

  .map {
    margin-top: 30px;
  }

  .blog-post-card .blog-post-text {
    padding: 15px;
  }

  .blog-post-card .blog-post-text ul {
    margin-bottom: 20px;
  }

  .blog-post-card .blog-post-text ul li {
    margin-right: 4px;
    font-size: 12px;
    padding-left: 20px;
  }

  .blog-post-card .blog-post-text ul li img {
    max-width: 15px;
  }

  .blog-post-card .blog-post-text ul li span {
    padding: 7px 10px;
    font-size: 12px;
  }

  .blog-post-card .blog-post-text ul li a {
    font-size: 12px;
  }

  .blog-post-card .blog-post-text h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .blog-post-btn {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .blog-post-right-bar {
    padding-left: 0;
  }

  .recent-posts-card .recent-posts-item .recent-text {
    margin-left: 10px;
  }

  .recent-posts-card .recent-posts-item .recent-text h3 {
    font-size: 16px;
  }

  .blog-post-category ul li {
    margin-bottom: 5px;
  }

  .blog-post-category ul li a {
    font-size: 13px;
  }

  .blog-post-category h2 {
    font-size: 18px;
  }

  .popular-tags h2 {
    font-size: 18px;
  }

  .popular-tags ul li a {
    padding: 10px;
    font-size: 12px;
  }

  .my-account-content {
    padding: 20px;
  }

  .my-account-content.register-form {
    padding: 20px;
  }

  .my-account-content h2 {
    font-size: 20px;
  }

  .my-account-content .text-account {
    float: left;
    margin-bottom: 20px;
  }

  .my-account-content .border-or::before {
    display: none;
  }

  .my-account-content .border-or::after {
    display: none;
  }

  .coming-soon-content {
    padding: 15px;
  }

  .coming-soon-content #timer div {
    margin-right: 6px;
    font-size: 35px;
    padding: 11px 21px 37px 20px;
    margin-bottom: 20px;
  }

  .coming-soon-content #timer span {
    bottom: 15px;
  }

  .single-blog-left-content {
    margin-bottom: 30px;
  }

  .blog-image img {
    border-radius: 10px;
  }

  .blog-hover-content {
    margin-left: 0;
  }

  .blog-hover-content ul li .single-hover-content {
    padding: 15px;
  }

  .blog-hover-content ul li .single-hover-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .why-the-conference-image.about-page-conference {
    margin-bottom: 20px;
    margin-right: 0;
    margin-left: 0;
  }

  .page-banner-area {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .page-banner-area .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 540px;
  }

  .page-banner-area .page-banner-shape-2 {
    display: none;
  }

  .page-banner-area .page-banner-shape-1 {
    display: none;
  }

  .page-banner-area .page-banner-shape-3 {
    display: none;
  }

  .single-events-item .events-image {
    border-radius: 10px;
  }

  .single-events-item .events-content {
    padding: 15px;
  }

  .single-events-item .events-content h3 {
    font-size: 18px;
    margin-bottom: 11px;
  }

  .single-events-item .events-content p {
    margin-bottom: 15px;
  }

  .single-events-item .events-content ul {
    margin-left: 0;
  }

  .single-events-item .events-content ul li {
    margin-right: 5px;
    font-size: 13px;
    padding-left: 25px;
  }

  .single-events-item .events-content ul .events-list-cart {
    padding: 6px 6px 6px 25px;
  }

  .single-events-item .events-content ul .events-list-cart img {
    left: 5px;
    max-width: 14px;
  }

  .single-event-details-content {
    padding-right: 0;
  }

  .single-event-details-content .event-details-img {
    margin-bottom: 20px;
  }

  .single-event-details-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .single-event-details-content p {
    margin-bottom: 10px;
  }

  .single-event-details-content .event-details-card {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .single-event-details-content .event-details-card img {
    position: unset;
    transform: translateY(0);
    margin-bottom: 20px;
  }

  .single-event-details-content .event-details-list {
    margin-bottom: 20px;
  }

  .single-event-details-content .event-details-list li {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .single-event-details-content .event-details-list li img {
    max-width: 20px;
  }

  .sponsors-upcoming-area .section-title h2 {
    font-size: 24px;
  }

  .sponsors-countdown .upcoming-list {
    margin-bottom: 0;
  }

  .sponsors-countdown .upcoming-list .live-auctions-countdown {
    margin-bottom: 30px;
  }

  .sponsors-countdown .upcoming-list .live-auctions-countdown .countdown-content-10 span {
    font-size: 30px;
  }

  .sponsors-countdown .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape {
    max-width: 100px;
  }

  .event-information-card {
    padding: 20px;
  }

  .event-information-card ul li {
    font-size: 14px;
  }

  .event-information-card ul li span {
    font-size: 13px;
  }

  .single-page-banner-content h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .mobile-nav .logo {
    top: 50%;
    transform: translateY(-50%);
    max-width: 140px;
    left: -23px;
  }

  .conference-planning-area .col-lg-4:nth-child(1) .single-conference-card {
    margin-right: 0;
  }

  .conference-planning-area .col-lg-4:nth-child(2) .single-conference-card {
    margin-right: 0;
    left: 0;
  }

  .conference-planning-area .col-lg-4:nth-child(3) .single-conference-card {
    margin-left: 0;
  }

  .single-conference-card {
    padding: 40px 20px 30px 20px;
  }

  .single-conference-card .planning-icon {
    width: 60px;
    height: 60px;
    line-height: 67px;
    font-size: 30px;
    left: 20px;
    top: -28px;
  }

  .conference-schedules-tab .nav.nav-tabs {
    margin-bottom: 20px;
  }

  .conference-schedules-tab .nav.nav-tabs .nav-item .nav-link {
    padding: 10px 8px !important;
    font-size: 13px;
  }

  .banner-three-img {
    text-align: center;
  }

  .banner-three-img.banner-three-img20 {
    text-align: center;
  }

  .footer-widget.footer-widget-link {
    padding-left: 0;
  }

  .footer-widget.footer-widget-link2 {
    left: 0;
  }

  .footer-instagram {
    left: 0;
  }

  .conference-schedules-content {
    padding: 20px;
  }

  .conference-schedules-content .single-schedules-item {
    display: block;
  }

  .conference-schedules-content .single-schedules-item .schedules-images {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .conference-schedules-content .single-schedules-item .schedules-images img {
    width: 100%;
  }

  .conference-schedules-content .single-schedules-item .schedules-text {
    margin-bottom: 20px;
  }

  .conference-schedules-content .single-schedules-item .schedules-text span {
    margin-bottom: 15px;
  }

  .conference-schedules-content .schedules-design-content {
    padding-right: 0;
  }

  .conference-schedules-content .schedules-design-content p {
    margin-bottom: 20px;
  }

  .blog-right-hover-content {
    margin-left: 0;
  }

  .blog-hover-content ul li .single-hover-content ul li {
    font-size: 13px;
    margin-right: 15px;
    padding-left: 25px;
  }

  .blog-hover-content ul li .single-hover-content ul li img {
    max-width: 13px;
  }

  .blog-hover-content ul li .single-hover-content ul li a {
    font-size: 13px;
  }

  .blog-hover-content ul li .single-hover-content h3 {
    font-size: 18px;
  }

  .blog-hover-content ul li .single-hover-content .read-more {
    font-size: 14px;
  }

  .blog-hover-content ul li .single-hover-content .read-more i {
    font-size: 14px;
  }

  .why-the-conference-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 75px;
  }

  .why-the-conference-image .why-the-conference-img-2 {
    bottom: -50px;
    left: -20px;
  }

  .why-the-conference-image .why-the-conference-img-3 {
    position: relative;
    margin-bottom: 20px;
    max-width: 200px;
    margin-left: auto;
    right: -20px;
    bottom: -20px;
  }

  .why-the-conference-image .why-the-conference-img-2 {
    margin-top: 20px;
  }

  .why-the-conference-image .why-the-conference-img-2 img {
    margin: 0;
  }

  .why-the-conference-content {
    margin-left: 0;
  }

  .why-the-conference-content ul li {
    font-size: 13px;
    padding-left: 30px;
  }

  .testimonials-card {
    padding: 15px;
  }

  .testimonials-card .testimonials-cliend h3 {
    font-size: 15px;
  }

  .testimonials-card .testimonials-icon {
    top: 20px;
    right: 30px;
  }

  .location-conference-form .form-group .form-control {
    font-size: 14px;
  }

  .location-conference-form .form-group .form-control::-moz-placeholder {
    font-size: 14px;
  }

  .location-conference-form .form-group .form-control:-ms-input-placeholder {
    font-size: 14px;
  }

  .location-conference-form .form-group .form-control::placeholder {
    font-size: 14px;
  }

  .location-conference-form .form-group .form-select {
    font-size: 14px;
  }

  .location-image {
    margin-bottom: 30px;
  }

  .location-image .location-shape {
    left: 0;
  }

  .location-conference-form {
    padding: 15px;
    margin-bottom: 30px;
  }

  .submenu-area .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 540px;
  }

  .single-conference-card::after {
    display: none;
  }

  .hero-slider-item {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .hero-slider-item .container-fluid {
    max-width: 540px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-slider-item .hero-shape-1 {
    display: none;
  }

  .hero-slider-item .hero-shape-2 {
    display: none;
  }

  .hero-slider-item .hero-shape-3 {
    display: none;
  }

  .hero-slider-item .hero-shape-4 {
    display: none;
  }

  .hero-slider-item .hero-shape-5 {
    display: none;
  }

  .hero-content h1 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .hero-content p {
    margin-bottom: 20px;
  }

  .hero-content .hero-bottom .default-btn.btn-style-one {
    margin-left: 10px;
  }

  .single-conference-img {
    padding-right: 0;
  }

  .single-conference-img .conference-img-2 {
    text-align: center;
    top: 35px;
  }

  .single-conference-img .conference-img-2 img {
    max-width: 255px;
  }

  .single-conference-img .conference-img-3 {
    max-width: 320px;
    border-left: none;
    bottom: 20px;
  }

  .single-conference-img .conference-experience {
    max-width: 272px;
    padding: 10px 10px 10px 90px;
    right: 15px;
    margin-bottom: 10px;
    bottom: 24px;
  }

  .single-conference-img .conference-experience i {
    left: 28px;
  }

  .footer-area.footer-style-two .footer-instagram h2 {
    left: 0;
  }

  .footer-area.footer-style-two .footer-instagram .row {
    margin: 0 -5px;
  }

  .submenu-left-content {
    text-align: center;
    margin-bottom: 8px;
  }

  .submenu-left-content ul li {
    margin-right: 0;
    margin-bottom: 5px;
    padding-left: 20px;
  }

  .submenu-left-content ul li p {
    font-size: 13px;
  }

  .submenu-left-content ul li a {
    font-size: 13px;
  }

  .submenu-left-content ul li img {
    max-width: 15px;
  }

  .submenu-right-content {
    text-align: center;
  }

  .submenu-right-content ul li p {
    font-size: 13px;
  }

  .hero-slider-two-item {
    padding-top: 60px;
  }

  .hero-slider-two-item .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 540px;
  }

  .hero-content-tow {
    top: 0;
    text-align: center;
  }

  .hero-content-tow h1 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 10px;
  }

  .hero-content-tow p {
    font-size: 13px;
    margin-bottom: 30px;
  }

  .hero-content-tow .upcoming-list {
    margin-bottom: 0;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown {
    margin-bottom: 40px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 span {
    font-size: 20px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 p {
    font-size: 12px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape {
    top: -7px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape img {
    height: 68px;
  }

  .hero-content-tow .hero-bottom {
    margin-bottom: 30px;
  }

  .hero-content-tow .hero-bottom .default-btn {
    margin-right: 8px;
  }

  .hero-image {
    right: 0;
  }

  .single-planning-content .planning-card {
    padding: 20px;
  }

  .single-planning-content .planning-card h3 {
    font-size: 17px;
    margin-bottom: 8px;
  }

  .join-conference-image {
    text-align: left;
    margin-bottom: 30px;
  }

  .join-conference-image .join-conference-image-two {
    bottom: 50px;
    right: 0;
  }

  .join-conference-image .join-conference-image-two img {
    max-width: 250px;
  }

  .join-conference-image .conference-experience {
    margin-top: 20px !important;
    max-width: 275px;
    margin: auto;
    padding: 15px 10px 15px 80px;
    top: 20%;
    right: 14px;
  }

  .join-conference-image .conference-experience i {
    left: 18px;
  }

  .join-conference-image .conference-experience .odometer-content h2 {
    font-size: 14px;
  }

  .join-conference-image .conference-experience .odometer-content h2 .target {
    font-size: 14px;
  }

  .join-conference-content {
    padding-left: 0;
  }

  .join-conference-content .join-conference-card {
    padding-left: 80px;
  }

  .join-conference-content .join-conference-card .join-sponsor-icon {
    font-size: 28px;
  }

  .upcoming-area .section-title h2 {
    font-size: 24px;
  }

  .upcoming-area .upcoming-shape-1 {
    display: none;
  }

  .upcoming-area .upcoming-shape-2 {
    display: none;
  }

  .upcoming-list .live-auctions-countdown li {
    font-size: 12px;
    width: 100px;
    height: 90px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .upcoming-list .live-auctions-countdown li:last-child {
    margin-right: 20px;
  }

  .upcoming-list .live-auctions-countdown li span {
    font-size: 25px;
  }

  .upcoming-list .upcoming-btn {
    margin-top: 15px;
  }

  .team-card.team-card-two .team-images {
    border-radius: 5px !important;
    margin-bottom: 18px;
  }

  .team-two-area .col-lg-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .team-card h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .schedules-style-two-tabs .nav.nav-pills .nav-item {
    margin-right: 15px;
  }

  .schedules-style-two-tabs .nav.nav-pills .nav-item .nav-link {
    padding: 12px 21px;
    font-size: 12px;
  }

  .schedules-style-two-content {
    padding: 15px;
  }

  .schedules-style-two-content .digital-schedules-item {
    left: 0;
    margin-top: 20px;
  }

  .schedules-style-two-content .digital-schedules-item h3 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .schedules-style-two-content .digital-schedules-item p {
    margin-bottom: 15px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client h4 {
    font-size: 14px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client p {
    font-size: 13px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers {
    padding-left: 105px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-bg-img-two {
    left: 50px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-text span {
    font-size: 13px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-text p {
    font-size: 12px;
  }

  .schedules-style-two-content .schedules-style-list ul li {
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 25px;
  }

  .plans-switcher {
    margin-bottom: 30px;
  }

  .single-plans-table {
    padding: 30px;
  }

  .single-plans-table .plans-header {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .single-plans-table .plans-header .price {
    font-size: 35px;
    margin-bottom: 5px;
  }

  .single-plans-table .plans-header span {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .single-plans-table .plans-features-list {
    margin-bottom: 20px;
  }

  .single-plans-table .plans-features-list li {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .why-the-conference-content.conference-style-two-content {
    padding: 20px;
    margin-left: 0;
    margin-top: 30px;
  }

  .why-the-conference-content.conference-style-two-content .section-title {
    margin-bottom: 15px;
  }

  .why-the-conference-content.conference-style-two-content ul {
    margin-bottom: 15px;
  }

  .why-the-conference-content.conference-style-two-content ul li {
    font-size: 14px;
    margin-bottom: 7px;
    padding-left: 34px;
  }

  .single-blog-style-two-card .blog-style-two-content h3 {
    font-size: 18px;
    margin-top: 20px;
  }

  .single-blog-style-two-card .blog-style-two-content .date {
    right: 20px;
    padding: 5px;
    max-width: 65px;
    top: -66px;
  }

  .single-blog-style-two-card .blog-style-two-content .date span {
    font-size: 13px;
    margin-bottom: 0;
  }

  .single-blog-style-two-card .blog-style-two-content .date p {
    font-size: 13px;
  }

  .subscribed-form-item {
    padding: 20px;
    margin-bottom: 60px;
  }

  .subscribed-form-item .section-title {
    margin-bottom: 25px;
  }

  .subscribed-form-item .section-title h2 {
    font-size: 23px;
  }

  .subscribed-form-item .subscribed-shape {
    display: none;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .form-control {
    padding: 17px 30px;
    font-size: 13px;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .form-control::-moz-placeholder {
    font-size: 13px;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .form-control:-ms-input-placeholder {
    font-size: 13px;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .form-control::placeholder {
    font-size: 13px;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .default-btn {
    position: unset;
    margin-top: 20px;
    width: 100%;
    padding: 17px 30px;
  }

  .footer-widget {
    margin-bottom: 30px;
  }

  .footer-widget img {
    margin-bottom: 15px;
  }

  .footer-widget h2 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .footer-widget .footer-widget-list li {
    margin-bottom: 8px;
  }

  .footer-widget .footer-widget-list li a {
    font-size: 13px;
  }

  .footer-instagram {
    margin-bottom: 20px;
    padding-right: 10px;
  }

  .footer-instagram h2 {
    font-size: 16px;
    margin-bottom: 15px;
    left: 0;
  }

  .footer-instagram .row {
    margin: 0 -5px;
  }

  .copyright-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .copyright-content p {
    font-size: 13px;
  }

  .copyright-content p b {
    font-size: 13px;
  }

  .copyright-content p a {
    font-size: 13px;
  }

  .footer-subscribe {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .footer-area.footer-style-two {
    padding-top: 60px;
  }

  .footer-area.footer-style-two .footer-widget.footer-widget-link {
    padding-left: 0;
  }

  .footer-area.footer-style-two .footer-widget.services-widget {
    padding-left: 0;
  }

  .copyright-content {
    margin-top: 40px;
  }

  .join-conference-content .join-conference-item {
    display: block;
    text-align: left;
  }

  .join-conference-content .join-conference-item img {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .join-conference-content .join-conference-item p {
    text-align: left;
    font-size: 13px;
  }

  .join-conference-content .join-conference-card h3 {
    font-size: 17px;
  }

  .event-photo-slider.owl-theme .owl-nav {
    margin-top: 30px;
  }

  .event-photo-slider.owl-theme .owl-nav [class*=owl-] {
    position: unset;
    transform: translateY(0);
  }

  .banner-three-area .container-fluid {
    max-width: 540px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .banner-three-area .banner-three-shape {
    display: none;
  }

  .single-banner-three-content {
    margin-top: 25px;
    margin-bottom: 30px;
  }

  .single-banner-three-content h1 {
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 10px;
  }

  .single-banner-three-content p {
    margin-bottom: 20px;
  }

  .single-banner-three-content .banner-input-fill .form-group {
    margin-bottom: 10px;
  }

  .single-banner-three-content .banner-input-fill .form-group::after {
    display: none;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control {
    padding: 10px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-select {
    padding: 10px;
  }

  .single-banner-three-content .banner-input-fill .banner-three-btn .default-btn {
    width: 100%;
  }

  .single-category-card {
    padding: 45px 10px 45px 60px;
  }

  .single-conference-content .conference-item {
    padding-left: 65px;
  }

  .single-conference-content .conference-item h3 {
    font-size: 16px;
  }

  .single-conference-content .conference-item .conference-icon {
    font-size: 20px;
    width: 50px;
    height: 50px;
    line-height: 53px;
  }

  .conference-style-three-img {
    margin-top: 35px;
    margin-bottom: 0;
  }

  .conference-style-three-img .conference-style-3 {
    display: none;
  }

  .schedules-style-three-content .schedules-speakers-img {
    margin-left: unset;
  }

  .schedules-style-3-tabs .nav.nav-pills .nav-item {
    margin-bottom: 20px;
    margin-right: 15px;
  }

  .schedules-style-3-tabs .nav.nav-pills .nav-item:last-child {
    margin-right: 15px;
  }

  .schedules-style-3-tabs .nav.nav-pills .nav-item .nav-link {
    padding: 14px 28px;
    font-size: 12px;
  }

  .schedules-style-three-area .section-title h2 {
    font-size: 20px;
  }

  .schedules-style-three-content {
    padding: 15px;
  }

  .schedules-style-three-content .schedules-style-list ul {
    margin-bottom: 20px;
  }

  .schedules-style-three-content .schedules-style-list ul li {
    font-size: 13px;
    padding-left: 26px;
    margin-bottom: 10px;
  }

  .schedules-style-three-content .digital-schedules-item h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .schedules-style-three-content .schedules-speakers-img .d-flex {
    display: block !important;
  }

  .schedules-style-three-content .schedules-speakers-img ul {
    margin-bottom: 20px;
  }

  .schedules-style-three-content .schedules-speakers-img .speakers-text {
    margin-left: 0;
  }

  .team-card.team-card-three {
    margin-bottom: 45px;
  }

  .sponsor-logo-img {
    margin-bottom: 0;
    margin-top: 30px;
    text-align: center;
  }

  .sponsor-logo-area .section-title h2 {
    margin-bottom: 0;
  }

  .faqs-img {
    padding-right: 0;
  }

  .single-faqs-content {
    padding-left: 0;
    margin-top: 30px;
  }

  .single-faqs-content .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 14px;
    padding: 15px;
  }

  .single-faqs-content .accordion .accordion-item .accordion-body {
    padding: 15px;
  }

  .blog-style-three-area .container-fluid {
    left: auto;
    max-width: 540px;
  }

  .schedules-style-three-content .digital-schedules-item {
    max-width: unset;
  }

  .blog-style-three-slider.owl-theme .owl-nav {
    position: unset;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .blog-style-three-area .section-title {
    margin-bottom: 30px;
  }

  .single-blog-style-three-card .blog-style-two-content {
    padding: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .single-blog-style-three-card .blog-style-two-content h3 {
    font-size: 16px;
  }

  .single-blog-style-three-card .blog-style-two-content ul li {
    margin-right: 20px;
  }

  .hero-content p {
    font-size: 13px;
  }
  /*thulasi*/
  .stage-content h3{
    margin: 15px 0 10px 0;
  }
  .logo{
    width: 40%;
  }
  .footer-widget img{
    width: 100%;
  }
   .conference-item h3 br{
    display: none;
   }
   .conference-item h3{
    padding-top: 15px;
   }

   .margin-lr{
    margin: 0;
   }

   .footer-icons{
    font-size: 15px;
   }
   .footer-icons .icons{
    width: 40px;
    height: 40px;
    line-height: 38px;
   }
   /*thulasi*/
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .conference-schedules-tab .nav.nav-tabs .nav-item .nav-link {
    padding: 10px 19.8px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .headding {
    margin-top: 50px;
  }

  .pt-100 {
    padding-top: 80px;
  }

  .pb-100 {
    padding-bottom: 80px;
  }

  .pb-70 {
    padding-bottom: 50px;
  }

  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  p {
    font-size: 15px;
  }

  .default-btn {
    font-size: 14px;
    padding: 14.5px 25px;
  }

  .default-btn i {
    margin-left: 4px;
    font-size: 17px;
  }

  .banner-three-img {
    padding-right: 0;
  }

  .banner-three-img.banner-three-img20 {
    padding-left: 0;
    padding-right: 0;
  }

  .section-title {
    max-width: 600px;
  }

  .section-title h2 {
    font-size: 30px;
  }

  .footer-area.footer-style-two .footer-instagram h2 {
    left: 0;
  }

  .footer-area.footer-style-two .footer-instagram .row {
    margin: 0 -5px;
  }

  .single-banner-three-content .banner-input-fill {
    padding: 15px 15px 15px 34px !important;
  }

  .single-banner-three-content .banner-input-fill .form-group::after {
    right: 15px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 {
    right: 15px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 span {
    font-size: 28px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape {
    margin-left: unset;
    max-width: 130px;
    left: 15px;
    top: -15px;
  }

  .single-banner-three-content .banner-input-fill .banner-select img {
    right: 0;
    margin-right: unset;
  }

  .single-events-item .events-image {
    border-radius: 10px;
  }

  .single-events-item .events-image img {
    height: 250px;
    width: 100%;
    border-radius: 10px;
  }

  .single-events-item .events-content {
    padding: 15px !important;
  }

  .single-events-item .events-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .single-events-item .events-content p {
    font-size: 14px;
  }

  .single-events-item .events-content ul {
    margin-left: 0;
  }

  .single-events-item .events-content ul li {
    font-size: 14px;
    margin-right: 10px;
    padding-left: 20px;
  }

  .single-events-item .events-content ul li img {
    max-width: 12px;
  }

  .single-events-item .events-content ul .events-list-cart {
    padding: 10px 13px 10px 30px;
  }

  .single-blog-details-content h2 {
    font-size: 25px;
  }

  .single-blog-details-content h3 {
    font-size: 25px;
  }

  .single-blog-details-content .event-details-card {
    padding: 30px 30px 30px 120px;
  }

  .single-blog-details-content .event-details-card img {
    left: 35px;
  }

  .single-blog-details-content .blog-details-comment .comment-text .client-name h4 {
    font-size: 16px;
  }

  .single-blog-details-content .blog-details-comment p {
    font-size: 14px;
  }

  .single-blog-details-content .leave-comment {
    margin-bottom: 30px;
  }

  .single-blog-details-content .leave-comment h3 {
    font-size: 25px;
  }

  .hero-area-two .container-fluid {
    max-width: 720px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .logo-light {
    display: none;
  }

  .single-contact-card {
    padding: 30px;
    margin-top: 30px;
  }

  .single-contact-card .contact-box {
    margin-bottom: 20px;
  }

  .single-contact-card .contact-box ul li a {
    font-size: 13px;
  }

  .contact-form-content {
    margin-right: 0;
  }

  .contact-form-content .form-group .form-control {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-control::-moz-placeholder {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-control:-ms-input-placeholder {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-control::placeholder {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-check .form-check-label {
    font-size: 13px;
  }

  .contact-form-content .form-group .form-check .form-check-label a {
    font-size: 13px;
  }

  .others-option-vg {
    display: none !important;
  }

  .map {
    margin-top: 30px;
  }

  .why-the-conference-image .why-the-conference-img-3 {
    right: 78px;
  }

  .location-conference-form {
    margin-bottom: 30px;
  }

  .single-event-details-content {
    padding-right: 0;
  }

  .single-event-details-content h2 {
    font-size: 25px;
  }

  .blog-area .blog-shape {
    display: none;
  }

  .blog-hover-content ul li .single-hover-content {
    padding: 15.6px;
  }

  .blog-hover-content ul li .single-hover-content h3 {
    font-size: 18px;
    margin-bottom: 14.8px;
    line-height: 30px;
  }

  .single-blog-left-content {
    margin-bottom: 30px;
  }

  .single-blog-left-content .section-title h2 {
    font-size: 29px;
  }

  .navbar-area {
    background-color: black;
    padding: 45px 0px;
  }

  .navbar-area.is-sticky {
    background-color: black;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
  }

  .navbar-nav {
    max-height: 50vh;
    overflow-y: scroll;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }

  .navbar-nav::-webkit-scrollbar {
    width: 3px;
  }

  .navbar-nav::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .navbar-nav::-webkit-scrollbar-thumb {
    background: #888;
  }

  .navbar-nav::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .others-option-for-responsive .container .container {
    top: 45px;
    max-width: 280px;
    right: 0;
    left: auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
    padding: 20px;
    border-radius: 10px;
    z-index: 99;
  }

  .others-option-for-responsive .others-option {
    display: block !important;
    text-align: center;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 15.5px 25px;
    border: none;
    outline: 0;
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input::-moz-placeholder {
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:-ms-input-placeholder {
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input::placeholder {
    font-family: var(--bodyFontFamily);
    font-weight: 400;
    font-size: 16px;
    color: #AEAEAE;
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:focus::-moz-placeholder {
    color: transparent;
    -moz-transition: var(--transition);
    transition: var(--transition);
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:focus:-ms-input-placeholder {
    color: transparent;
    -ms-transition: var(--transition);
    transition: var(--transition);
  }

  .others-option-for-responsive .others-option .option-item .search-form .search-input:focus::placeholder {
    color: transparent;
    transition: var(--transition);
  }

  .others-option-for-responsive .others-option .option-item .search-button {
    position: absolute;
    right: 20px;
    bottom: 0;
    top: 19px;
    border: none;
    background-color: var(--optionalColor);
    width: 50px;
    height: 55px;
    color: var(--whiteColor);
    line-height: 55px;
    padding: 0 !important;
    border-radius: 5px;
    z-index: 1;
  }

  .others-option-for-responsive .others-option .option-item .search-button i {
    font-size: 20px;
    position: relative;
    top: 3px;
  }

  .others-option-for-responsive .others-option .option-item .search-button:hover::after {
    width: 50%;
  }

  .others-option-for-responsive .others-option .option-item .search-button:hover::before {
    width: 50%;
  }

  .others-option-for-responsive .others-option .option-item .default-btn {
    margin-top: 20px;
  }

  .mean-container .mean-nav {
    margin-top: 44px;
  }

  .mean-container .mean-nav ul li a.mean-expand {
    margin-top: 0;
    width: 92%;
  }

  .sponsors-countdown .upcoming-list {
    margin-bottom: 0;
  }

  .sponsors-countdown .upcoming-list .live-auctions-countdown {
    margin-bottom: 48px;
  }

  .sponsors-countdown .upcoming-list .live-auctions-countdown .countdown-content-10 span {
    font-size: 40px;
  }

  .sponsors-countdown .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape {
    top: -13px;
  }

  .blog-post-card .blog-post-text {
    padding: 15px;
  }

  .blog-post-card .blog-post-text ul {
    margin-bottom: 20px;
  }

  .blog-post-card .blog-post-text ul li {
    margin-right: 15px;
    font-size: 12px;
    padding-left: 20px;
  }

  .blog-post-card .blog-post-text ul li img {
    max-width: 15px;
  }

  .blog-post-card .blog-post-text ul li span {
    padding: 7px 10px;
    font-size: 12px;
  }

  .blog-post-card .blog-post-text ul li a {
    font-size: 12px;
  }

  .blog-post-card .blog-post-text h3 {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .blog-post-btn {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .blog-post-right-bar {
    padding-left: 0;
  }

  .recent-posts-card .recent-posts-item .recent-text {
    margin-left: 10px;
  }

  .recent-posts-card .recent-posts-item .recent-text h3 {
    font-size: 16px;
  }

  .blog-post-category ul li {
    margin-bottom: 5px;
  }

  .blog-post-category ul li a {
    font-size: 13px;
  }

  .blog-post-category h2 {
    font-size: 18px;
  }

  .popular-tags h2 {
    font-size: 18px;
  }

  .popular-tags ul li a {
    padding: 10px;
    font-size: 12px;
  }

  .why-the-conference-image.about-page-conference {
    margin-right: 0;
    margin-left: 0;
  }

  .error-content h1 {
    font-size: 100px;
    margin-bottom: 15px;
  }

  .error-content h2 {
    font-size: 20px;
  }

  .page-banner-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .page-banner-area .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 720px;
  }

  .single-events-item .events-content {
    padding: 0px 15px 0px 8px;
  }

  .privacy-text h3 {
    font-size: 22px;
  }

  .mobile-nav .logo {
    top: 50%;
    transform: translateY(-50%);
    max-width: 140px;
    left: 0 !important;
  }

  .main-nav .container-fluid {
    max-width: 720px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .submenu-area .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 720px;
  }

  .hero-slider-item {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .hero-slider-item.bg-img-1 .hero-content {
    margin-left: unset;
  }

  .hero-slider-item .container-fluid {
    max-width: 720px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-slider-item .hero-shape-1 {
    display: none;
  }

  .hero-slider-item .hero-shape-2 {
    display: none;
  }

  .hero-slider-item .hero-shape-3 {
    display: none;
  }

  .hero-slider-item .hero-shape-4 {
    display: none;
  }

  .hero-slider-item .hero-shape-5 {
    display: none;
  }

  .hero-content h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }

  .hero-content p {
    margin-bottom: 20px;
  }

  .single-conference-img {
    padding-right: 0;
    margin-bottom: 60px;
  }

  .single-conference-img .conference-img-2 {
    left: 40px;
    top: 10px;
  }

  .single-conference-img .conference-img-2 img {
    width: 400px;
    height: 155px;
  }

  .single-conference-img .conference-img-3 {
    border-left: none;
  }

  .why-the-conference-image .why-the-conference-img-2 img {
    margin-left: 0;
  }

  .banner-three-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .banner-three-area .col-lg-6:nth-child(1) {
    order: 1;
  }

  .banner-three-area .col-lg-6:nth-child(2) {
    order: 1;
  }

  .banner-three-area .col-lg-12 .banner-input-fill .col-lg-3:nth-child(1) {
    order: unset;
  }

  .banner-three-area .col-lg-12 .banner-input-fill .col-lg-3:nth-child(2) {
    order: unset;
  }

  .single-conference-card::after {
    display: none;
  }

  .conference-planning-area .col-lg-4:nth-child(1) .single-conference-card {
    margin-right: 0;
  }

  .conference-planning-area .col-lg-4:nth-child(2) .single-conference-card {
    margin-right: 0;
    left: 0;
  }

  .conference-planning-area .col-lg-4:nth-child(3) .single-conference-card {
    margin-left: 0;
  }

  .single-conference-card h3 {
    font-size: 18px;
  }

  .conference-schedules-tab .nav.nav-tabs .nav-item .nav-link {
    padding: 15px 31.3px !important;
  }

  .blog-right-hover-content {
    margin-left: 0;
  }

  .blog-hover-content ul li .single-hover-content ul li {
    font-size: 14px;
    margin-right: 18px;
    padding-left: 25px;
  }

  .blog-hover-content ul li .single-hover-content ul li img {
    max-width: 14px;
  }

  .blog-hover-content ul li .single-hover-content ul li a {
    font-size: 14px;
  }

  .blog-hover-content ul li .single-hover-content .read-more {
    font-size: 14px;
  }

  .blog-hover-content ul li .single-hover-content .read-more i {
    font-size: 14px;
  }

  .conference-schedules-content .single-schedules-item {
    margin-bottom: 20px;
  }

  .conference-schedules-content .schedules-design-content {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .why-the-conference-image {
    margin-right: 20px;
    margin-left: 20px;
  }

  .why-the-conference-content {
    margin-left: 0;
    margin-top: 30px;
  }

  .why-the-conference-content .section-title.left-title {
    margin-bottom: 15px;
  }

  .why-the-conference-content ul {
    margin-bottom: 20px;
  }

  .location-image .location-shape {
    left: 0;
  }

  .banner-three-area .container-fluid {
    max-width: 720px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .banner-three-area .banner-three-shape {
    display: none;
  }

  .single-banner-three-content h1 {
    font-size: 64px;
    line-height: 75px;
    margin-bottom: 10px;
  }

  .single-banner-three-content p {
    margin-bottom: 20px;
  }

  .conference-style-three-img {
    margin-top: 40px;
  }

  .single-conference-content .conference-item h3 {
    font-size: 16px;
  }

  .schedules-style-three-content {
    padding: 15px;
  }

  .schedules-style-three-content .schedules-style-list ul li {
    font-size: 14px;
    padding-left: 25px;
    margin-bottom: 15px;
  }

  .schedules-style-three-content .digital-schedules-item h3 {
    font-size: 16px;
  }

  .schedules-style-three-content .schedules-speakers-img .d-flex {
    display: block !important;
  }

  .schedules-style-three-content .schedules-speakers-img ul {
    margin-bottom: 10px;
  }

  .schedules-style-three-content .schedules-speakers-img .speakers-text {
    margin-left: 0;
  }

  .schedules-style-three-content .schedules-speakers-img .speakers-text h5 {
    margin-bottom: 5px;
  }

  .schedules-style-3-tabs .nav.nav-pills .nav-item .nav-link {
    padding: 15px 45px;
  }

  .team-card.team-card-three {
    margin-bottom: 45px;
  }

  .sponsor-logo-area .section-title {
    margin-bottom: 30px;
  }

  .faqs-img {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .single-faqs-content {
    padding-left: 0;
  }

  .blog-style-three-slider.owl-theme .owl-nav {
    position: unset;
  }

  .banner-three-img {
    margin-top: 30px;
  }

  .banner-three-img.banner-three-img20 {
    margin-top: 30px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control::-moz-placeholder {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control:-ms-input-placeholder {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control::placeholder {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-select {
    font-size: 14px;
  }

  .hero-slider-two-item {
    padding-top: 80px;
  }

  .hero-slider-two-item .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 720px;
  }

  .hero-content-tow {
    top: 0;
    margin-bottom: 40px;
  }

  .hero-content-tow h1 {
    font-size: 55px;
    line-height: unset;
    margin-bottom: 10px;
  }

  .hero-content-tow .upcoming-list {
    margin-bottom: 60px;
  }

  .hero-image {
    right: 0;
  }

  .submenu-left-content ul li {
    padding-left: 20px;
    margin-right: 6px;
  }

  .submenu-left-content ul li img {
    max-width: 15px;
  }

  .submenu-left-content ul li a {
    font-size: 13px;
  }

  .submenu-left-content ul li p {
    font-size: 13px;
  }

  .submenu-right-content ul li {
    margin-right: 4px;
  }

  .submenu-right-content ul li p {
    font-size: 13px;
  }

  .single-planning-content .planning-card h3 {
    font-size: 18px;
  }

  .join-conference-image .conference-experience::after {
    width: 100%;
  }

  .blog-style-three-area .container-fluid {
    left: calc((100% - 720px) / 2);
  }

  .blog-style-three-area .section-title {
    margin-bottom: 30px;
  }

  .blog-style-three-area .section-title p {
    max-width: unset;
  }

  .single-blog-style-three-card .blog-style-two-content {
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .single-blog-style-three-card .blog-style-two-content ul {
    margin-bottom: 15px;
  }

  .single-blog-style-three-card .blog-style-two-content h3 {
    font-size: 18px;
  }

  .join-conference-content {
    padding-left: 0;
    margin-top: 30px;
  }

  .join-conference-content .join-conference-card h3 {
    font-size: 18px;
  }

  .upcoming-area .upcoming-shape-1 {
    max-width: 250px;
  }

  .upcoming-area .upcoming-shape-2 {
    max-width: 250px;
  }

  .upcoming-area .section-title {
    max-width: 545px;
  }

  .upcoming-list .live-auctions-countdown li {
    width: 115px;
    height: 115px;
    font-size: 13px;
  }

  .upcoming-list .live-auctions-countdown li span {
    font-size: 35px;
  }

  .team-two-area .col-lg-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .team-images {
    border-radius: 10px !important;
  }

  .team-card h3 {
    font-size: 18px;
  }

  .schedules-style-two-tabs .nav.nav-pills .nav-item .nav-link {
    padding: 18px 65px;
    font-size: 14px;
  }

  .schedules-style-two-content {
    padding: 25px;
  }

  .schedules-style-two-content .digital-schedules-item {
    left: 0;
  }

  .schedules-style-two-content .digital-schedules-item h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .schedules-style-two-content .digital-schedules-item p {
    margin-bottom: 10px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client {
    margin-bottom: 10px;
  }

  .schedules-style-two-content .schedules-speakers-img {
    text-align: center;
    margin-top: 30px;
  }

  .schedules-style-two-content .schedules-style-list {
    margin-bottom: 10px;
  }

  .schedules-style-two-content .schedules-style-list ul li {
    font-size: 15px;
    display: inline-block;
    margin-right: 20px;
  }

  .schedules-style-two-content .schedules-style-list ul li:last-child {
    margin-right: 0;
  }

  .single-plans-table .plans-header .price {
    font-size: 35px;
  }

  .single-plans-table .plans-features-list li {
    font-size: 15px;
  }

  .why-the-conference-content.conference-style-two-content {
    margin-left: 0;
    margin-top: 30px;
  }

  .why-the-conference-content.conference-style-two-content .section-title.left-title {
    margin-bottom: 15px;
  }

  .why-the-conference-content.conference-style-two-content ul {
    margin-bottom: 25px;
  }

  .single-blog-style-two-card .blog-style-two-content .date {
    right: 20px;
    max-width: 70px;
    padding: 8px;
    top: -75px;
  }

  .single-blog-style-two-card .blog-style-two-content .date span {
    font-size: 13px;
  }

  .single-blog-style-two-card .blog-style-two-content .date p {
    font-size: 13px;
  }

  .single-blog-style-two-card .blog-style-two-content h3 {
    font-size: 18px;
    margin-top: 25px;
  }

  .subscribed-form-item {
    margin-bottom: 80px;
  }

  .subscribed-form-item .section-title {
    margin-bottom: 30px;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .default-btn {
    padding: 17.5px 25px;
  }

  .footer-area.footer-style-two {
    padding-top: 80px;
  }

  .footer-area.footer-style-two .footer-widget.services-widget {
    padding-left: 100px;
    margin-top: 20px;
  }

  .footer-instagram {
    left: 0;
  }

  .footer-instagram h2 {
    left: 0;
  }

  .footer-instagram .row {
    margin: 0 -5px;
  }

  .footer-widget.footer-widget-link {
    margin-bottom: 30px;
  }

  .footer-widget.footer-widget-link2 {
    left: 0;
    margin-bottom: 30px;
  }

  .footer-widget h2 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .footer-widget .footer-widget-list li {
    margin-bottom: 10px;
  }

  .footer-widget .footer-widget-list li a {
    font-size: 14px;
  }

  .footer-subscribe {
    margin-left: 30px;
  }

  .footer-instagram h2 {
    font-size: 16px;
    margin-bottom: 10px;
    left: 0;
  }

  .footer-instagram .row {
    margin: 0 -5px;
  }

  .copyright-content {
    margin-top: 80px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .event-photo-slider.owl-theme .owl-nav {
    margin-top: 30px;
  }

  .event-photo-slider.owl-theme .owl-nav [class*=owl-] {
    position: unset;
    transform: translateY(0);
  }

  /*thulasi*/
  .stage-content h3{
    margin: 15px 0 10px 0;
  }
  .footer-subscribe{
    margin: 0;
  }
  .pb-100{
    padding-bottom: 89px;
  }
  .footer-widget.footer-widget-link2{
    margin-top: 30px;
  }
  .footer-subscribe{
    margin-top: 30px;
  }

   /*thulasi*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {



  .main-nav .container-fluid {
    max-width: 960px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-nav nav {
    padding-top: 0 !important;
  }

  .main-nav nav .navbar-nav {
    padding-top: 0 !important;
  }

  .main-nav nav .navbar-nav .nav-item {
    margin-right: 20px;
  }

  .main-nav nav .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }

  .main-nav nav .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
    font-size: 13px;
  }

  .main-nav nav .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link::before {
    font-size: 10px;
  }

  .main-nav .others-option-vg .option-item .search-form {
    margin-right: 15px;
    max-width: 165px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input {
    padding: 10.5px;
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input::-moz-placeholder {
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input:-ms-input-placeholder {
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input::placeholder {
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-button {
    width: 44px;
    height: 44px;
    line-height: 46px;
  }

  .main-nav .others-option-vg .option-item .default-btn {
    font-size: 13px;
    padding: 12.5px 15px;
  }

  .main-nav .others-option-vg .option-item .default-btn i {
    font-size: 13px;
    margin-left: 3px;
    top: 2.5px;
  }

  .hero-area-two .container-fluid {
    max-width: 960px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-slider-item .container-fluid {
    max-width: 960px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-area.footer-style-two .footer-instagram h2 {
    left: 0;
  }

  .footer-area.footer-style-two .footer-instagram .row {
    margin: 0 -5px;
  }

  .banner-three-img {
    padding-right: 0;
  }

  .banner-three-img.banner-three-img20 {
    padding-left: 0;
    padding-right: 0;
  }

  .banner-three-area {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .banner-three-area .col-lg-6 {
    order: 1;
  }

  .banner-three-area .col-lg-6 .banner-input-fill .col-lg-3:nth-child(1) {
    order: unset;
  }

  .banner-three-area .col-lg-6 .banner-input-fill .col-lg-3:nth-child(2) {
    order: unset;
  }

  .single-banner-three-content {
    text-align: center;
    max-width: 780px;
    margin: auto;
    margin-bottom: 50px;
  }

  .single-banner-three-content h1 {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 10px;
  }

  .single-banner-three-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .single-banner-three-content .banner-input-fill .form-group .banner-select img {
    right: 30px;
  }

  .single-banner-three-content .banner-input-fill .default-btn {
    padding: 13.5px 20px 15px 21px;
  }

  .hero-content {
    max-width: 587px;
  }

  .hero-content h1 {
    font-size: 78px;
    line-height: 90px;
  }

  .join-conference-style-three-area {
    padding-bottom: 127px;
  }

  .blog-right-hover-content {
    margin-left: 0;
  }

  .single-events-item .events-image img {
    height: 250px;
  }

  .single-events-item .events-content {
    padding: 0;
  }

  .single-events-item .events-content h3 {
    font-size: 18px;
  }

  .single-events-item .events-content p {
    font-size: 14px;
  }

  .single-events-item .events-content ul {
    margin-left: -128px;
  }

  .single-events-item .events-content ul li {
    margin-right: 5px;
    font-size: 14px;
    padding-left: 25px;
  }

  .single-events-item .events-content ul li img {
    max-width: 13px;
  }

  .location-branch-card {
    padding: 18px;
  }

  .single-conference-card::after {
    display: none;
  }

  .single-conference-content .conference-item h3 {
    font-size: 18px;
  }

  p {
    font-size: 15px;
  }

  .section-title h2 {
    font-size: 35px;
  }

  .blog-style-three-area .section-title p {
    font-size: 13px;
  }

  .default-btn {
    font-size: 13px;
    padding: 12.5px 15px;
  }

  .default-btn i {
    font-size: 13px;
    margin-left: 3px;
    top: 2.5px;
  }

  .single-event-details-content h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .single-event-details-content .event-details-card {
    padding: 35px 20px 35px 120px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .privacy-text h3 {
    font-size: 26px;
  }

  .single-contact-card {
    padding: 20px;
    margin-top: 30px;
  }

  .single-contact-card .contact-box {
    margin-bottom: 20px;
  }

  .single-contact-card .contact-box ul li a {
    font-size: 15px;
  }

  .blog-hover-content ul li .single-hover-content {
    padding: 38.2px;
  }

  .contact-form-content {
    margin-right: 0;
  }

  .contact-form-content .form-group .form-control {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-control::-moz-placeholder {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-control:-ms-input-placeholder {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-control::placeholder {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-check .form-check-label {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-check .form-check-label a {
    font-size: 15px;
  }

  .single-blog-details-content h2 {
    font-size: 23px;
  }

  .single-blog-details-content .blog-details-comment .comment-text {
    display: block;
  }

  .single-blog-details-content .blog-details-comment .comment-text img {
    margin-bottom: 10px;
  }

  .single-blog-details-content .blog-details-comment .comment-text .client-name {
    margin-left: 0;
  }

  .single-blog-details-content .event-details-card {
    padding: 30px 30px 30px 120px;
  }

  .single-blog-details-content .event-details-card img {
    left: 35px;
  }

  .conference-planning-area .col-lg-4:nth-child(1) .single-conference-card {
    margin-right: 0;
  }

  .conference-planning-area .col-lg-4:nth-child(2) .single-conference-card {
    margin-right: 0;
    left: 0;
  }

  .conference-planning-area .col-lg-4:nth-child(3) .single-conference-card {
    margin-left: 0;
  }

  .conference-schedules-tab .nav.nav-tabs .nav-item .nav-link {
    padding: 18px 51.3px !important;
  }

  .conference-schedules-content {
    padding: 15px;
  }

  .conference-schedules-content .single-schedules-item .schedules-images {
    margin-right: 13px;
  }

  .conference-schedules-content .single-schedules-item .schedules-text span {
    margin-bottom: 15px;
  }

  .conference-schedules-content .single-schedules-item .schedules-text .client-item {
    padding-left: 0;
  }

  .conference-schedules-content .single-schedules-item .schedules-text .client-item img {
    position: unset;
    transform: translateY(0);
  }

  .conference-schedules-content .schedules-btn .default-btn {
    padding: 15px;
  }

  .blog-post-card .blog-post-text {
    padding: 15px;
  }

  .blog-post-card .blog-post-text ul {
    margin-bottom: 20px;
  }

  .blog-post-card .blog-post-text ul li {
    margin-right: 4px;
    font-size: 12px;
    padding-left: 20px;
  }

  .blog-post-card .blog-post-text ul li img {
    max-width: 15px;
  }

  .blog-post-card .blog-post-text ul li span {
    padding: 7px 10px;
    font-size: 12px;
  }

  .blog-post-card .blog-post-text ul li a {
    font-size: 12px;
  }

  .blog-post-card .blog-post-text h3 {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .blog-post-btn {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .blog-post-right-bar {
    padding-left: 0;
  }

  .recent-posts-card .recent-posts-item .recent-text {
    margin-left: 10px;
  }

  .recent-posts-card .recent-posts-item .recent-text h3 {
    font-size: 16px;
  }

  .recent-posts-card .recent-posts-item .recent-text p {
    margin-bottom: 10px;
  }

  .blog-post-category ul li {
    margin-bottom: 5px;
  }

  .blog-post-category ul li a {
    font-size: 13px;
  }

  .blog-post-category h2 {
    font-size: 18px;
  }

  .popular-tags h2 {
    font-size: 18px;
  }

  .popular-tags ul li a {
    padding: 10px;
    font-size: 12px;
  }

  .testimonials-card {
    padding: 20px;
  }

  .blog-hover-content {
    margin-left: -25px;
  }

  .blog-hover-content ul li .single-hover-content h3 {
    margin-bottom: 19.5px;
  }

  .single-blog-left-content {
    margin-bottom: 30px;
  }

  .location-conference-form {
    padding: 15px;
  }

  .why-the-conference-content .section-title h2 {
    font-size: 30px;
  }

  .error-content h1 {
    font-size: 150px;
    margin-bottom: 15px;
  }

  .error-content h2 {
    font-size: 20px;
  }

  .single-conference-content .section-title h2 {
    font-size: 30px;
  }

  .single-conference-img {
    padding-right: 10px;
  }

  .event-information-card {
    padding: 15px;
  }

  .event-information-card ul li span {
    font-size: 14px;
  }

  .page-banner-area {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .logo-light {
    max-width: 95px;
  }

  .testimonials-card .testimonials-cliend h3 {
    font-size: 16px;
  }

  .submenu-area .container-fluid {
    max-width: 960px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-slider-two-item .container-fluid {
    max-width: 960px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-content-tow h1 {
    font-size: 68px;
    line-height: 75px;
  }

  .hero-content-tow p {
    font-size: 13px;
    margin-bottom: 30px;
  }

  .hero-content-tow .upcoming-list {
    margin-bottom: 40px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 span {
    font-size: 23px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 p {
    font-size: 11px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape {
    top: -7px;
  }

  .hero-content-tow .hero-bottom .default-btn {
    margin-right: 10px;
  }

  .single-planning-content .planning-card {
    padding: 20px;
  }

  .single-planning-content .planning-card h3 {
    font-size: 18px;
  }

  .why-the-conference-content.about-conference-content .section-title {
    margin-bottom: 20px;
  }

  .why-the-conference-content.about-conference-content .section-title h2 {
    font-size: 30px;
  }

  .banner-three-area .banner-three-shape-1 {
    display: none;
  }

  .event-photo-slider.owl-theme .owl-nav {
    margin-top: 30px;
  }

  .event-photo-slider.owl-theme .owl-nav [class*=owl-] {
    position: unset;
    transform: translateY(0);
  }

  .main-nav.main-style-two .container-fluid {
    max-width: 960px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-nav.main-style-two nav {
    padding-top: 20px !important;
  }

  .single-conference-content .conference-item {
    padding-left: 0;
  }

  .single-conference-content .conference-item .conference-icon {
    position: unset;
    margin-bottom: 15px;
  }

  .conference-style-three-img .conference-style-3 {
    bottom: -190px;
    left: 40px;
  }

  .schedules-style-3-tabs .nav.nav-pills .nav-item .nav-link {
    padding: 18px 60px;
  }

  .schedules-style-three-content {
    padding: 23px;
  }

  .schedules-style-three-content .schedules-style-list ul li {
    padding-left: 23px;
    margin-right: -30px;
  }

  .schedules-style-three-content .digital-schedules-item h3 {
    font-size: 18px;
    padding-left: 40px;
  }

  .schedules-style-three-content .schedules-speakers-img .d-flex {
    display: block !important;
  }

  .schedules-style-three-content .schedules-speakers-img .speakers-text {
    margin-left: 0;
    margin-top: 10px;
  }

  .schedules-style-three-content .schedules-speakers-img .speakers-text h5 {
    margin-bottom: 5px;
  }

  .single-blog-style-three-card .blog-style-two-content {
    padding: 25px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .single-blog-style-three-card .blog-style-two-content h3 {
    font-size: 18px;
  }

  .blog-style-three-slider.owl-theme .owl-nav {
    bottom: 25px;
  }

  .faqs-img {
    padding-right: 0;
    text-align: center;
    margin-bottom: 40px;
  }

  .blog-style-three-area .container-fluid {
    left: calc((100% - 960px) / 2);
  }

  .blog-style-three-area .section-title h2 {
    font-size: 24px;
  }

  .team-card.team-card-three .team-three-text {
    padding: 27px;
  }

  .single-category-card {
    padding: 40px 17px 40px 53px;
  }

  .banner-three-area .container-fluid {
    max-width: 960px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .join-conference-image .join-conference-image-two {
    bottom: -42px;
  }

  .join-conference-image .conference-experience {
    top: 20%;
  }

  .join-conference-image .conference-experience::after {
    width: 100%;
  }

  .join-conference-content {
    padding-left: 0;
  }

  .join-conference-content .section-title {
    margin-bottom: 20px;
  }

  .join-conference-content .section-title h2 {
    font-size: 30px;
  }

  .join-conference-content .join-conference-card {
    padding-left: 65px;
    margin-bottom: 20px;
  }

  .join-conference-content .join-conference-card h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .join-conference-content .join-conference-card .join-sponsor-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 25px;
  }

  .join-conference-content .join-conference-item {
    display: block;
  }

  .join-conference-content .join-conference-item img {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .upcoming-area .upcoming-shape-1 {
    max-width: 250px;
  }

  .upcoming-area .upcoming-shape-2 {
    max-width: 250px;
  }

  .upcoming-list .live-auctions-countdown li {
    width: 140px;
    height: 125px;
  }

  .upcoming-list .live-auctions-countdown li span {
    font-size: 40px;
  }

  .team-card h3 {
    font-size: 18px;
  }

  .schedules-style-two-content {
    padding: 15px;
  }

  .schedules-style-two-content .digital-schedules-item {
    left: -15px;
  }

  .schedules-style-two-content .digital-schedules-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .schedules-style-two-content .digital-schedules-item p {
    margin-bottom: 15px;
    font-size: 13px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client {
    padding-left: 50px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client h4 {
    font-size: 13px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client p {
    font-size: 13px;
  }

  .schedules-style-two-content .digital-schedules-item .col-lg-7 {
    padding-right: 0;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers {
    padding-left: 100px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-bg-img-two {
    left: 50px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-text span {
    font-size: 13px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-text p {
    font-size: 11px;
  }

  .schedules-style-two-content .schedules-style-list ul li {
    font-size: 14px;
  }

  .schedules-style-two-tabs .nav.nav-pills .nav-item .nav-link {
    padding: 15px 60px;
  }

  .single-plans-table {
    padding: 15px;
  }

  .single-plans-table .plans-header {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .single-plans-table .plans-header p {
    font-size: 13px;
  }

  .single-plans-table .plans-header .price {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .single-plans-table .plans-header span {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .single-plans-table .plans-features-list li {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .why-the-conference-content.conference-style-two-content {
    padding: 20px;
  }

  .why-the-conference-content.conference-style-two-content .section-title {
    margin-bottom: 15px;
  }

  .why-the-conference-content.conference-style-two-content .section-title h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .why-the-conference-content ul {
    margin-bottom: 15px;
  }

  .why-the-conference-content ul li {
    font-size: 15px;
    padding-left: 35px;
  }

  .single-blog-style-two-card .blog-style-two-content h3 {
    font-size: 16px;
    line-height: 25px;
    margin-top: 25px;
  }

  .single-blog-style-two-card .blog-style-two-content .date {
    right: 20px;
    max-width: 60px;
    padding: 7px;
    top: -74px;
  }

  .single-blog-style-two-card .blog-style-two-content .date span {
    font-size: 13px;
  }

  .single-blog-style-two-card .blog-style-two-content .date p {
    font-size: 13px;
  }

  .subscribed-form-item {
    padding: 50px;
    margin-bottom: -100px;
  }

  .subscribed-form-item .section-title h2 {
    font-size: 30px;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .form-control {
    padding: 16px 30px;
  }

  .footer-instagram {
    left: 0;
  }

  .footer-instagram h2 {
    left: 0;
  }

  .footer-instagram .row {
    margin: 0 -5px;
  }

  .footer-subscribe {
    margin-left: 0;
    margin-top: 25px;
  }

  .footer-subscribe h2 {
    font-size: 16px;
  }

  .footer-widget h2 {
    font-size: 16px;
  }

  .footer-widget p {
    margin-right: 0;
    font-size: 14px;
  }

  .footer-widget.footer-widget-link {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 25px;
  }

  .footer-widget.footer-widget-link2 {
    padding-left: 0;
    position: relative;
    left: 0;
    margin-bottom: 0;
    margin-top: 25px;
  }

  .footer-widget .footer-widget-list li {
    margin-bottom: 8px;
  }

  .footer-widget .footer-widget-list li a {
    font-size: 14px;
  }

  .footer-area.footer-style-two {
    padding-top: 200px;
  }

  .footer-area.footer-style-two .footer-widget.footer-widget-link {
    padding-left: 0;
  }

  .footer-area.footer-style-two .footer-widget.services-widget {
    padding-left: 0;
    margin-top: 35px;
  }

  .blog-hover-content ul li .single-hover-content ul li {
    font-size: 15px;
    padding-left: 25px;
  }

  .blog-hover-content ul li .single-hover-content ul li img {
    max-width: 15px;
  }

  .blog-hover-content ul li .single-hover-content ul li a {
    font-size: 14px;
  }

  .blog-hover-content ul li .single-hover-content .read-more {
    font-size: 15px;
  }

  .blog-hover-content ul li .single-hover-content .read-more i {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-nav .container-fluid {
    max-width: 1140px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-nav nav {
    padding-top: 0 !important;
  }

  .main-nav nav .navbar-nav {
    padding-top: 0 !important;
  }

  .main-nav nav .navbar-nav .nav-item {
    margin-right: 30px;
  }

  .main-nav nav .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }

  .main-nav nav .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
    font-size: 13px;
  }

  .main-nav nav .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link::before {
    font-size: 10px;
  }

  .main-nav .others-option-vg .option-item .search-form {
    margin-right: 15px;
    max-width: 165px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input {
    padding: 10.5px;
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input::-moz-placeholder {
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input:-ms-input-placeholder {
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-input::placeholder {
    font-size: 15px;
  }

  .main-nav .others-option-vg .option-item .search-form .search-button {
    width: 44px;
    height: 44px;
    line-height: 46px;
  }

  .main-nav .others-option-vg .option-item .default-btn {
    font-size: 13px;
    padding: 12.5px 15px;
  }

  .main-nav .others-option-vg .option-item .default-btn i {
    font-size: 13px;
    margin-left: 3px;
    top: 2.5px;
  }

  .hero-area-two .container-fluid {
    max-width: 1140px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-slider-item .container-fluid {
    max-width: 1140px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-area.footer-style-two .footer-instagram h2 {
    left: 0;
  }

  .footer-area.footer-style-two .footer-instagram .row {
    margin: 0 -5px;
  }

  .banner-three-img {
    padding-right: 0;
  }

  .banner-three-img.banner-three-img20 {
    padding-left: 0;
    padding-right: 0;
  }

  .footer-instagram h2 {
    left: 0;
  }

  .footer-instagram .row {
    margin: 0 -5px;
  }

  .single-contact-card {
    padding: 20px;
    margin-top: 30px;
  }

  .single-contact-card .contact-box {
    margin-bottom: 20px;
  }

  .single-contact-card .contact-box ul li a {
    font-size: 15px;
  }

  .banner-three-area {
    padding-top: 220px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control::-moz-placeholder {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control:-ms-input-placeholder {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control::placeholder {
    font-size: 14px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-select {
    font-size: 14px;
  }

  .contact-form-content {
    margin-right: 0;
  }

  .contact-form-content .form-group .form-control {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-control::-moz-placeholder {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-control:-ms-input-placeholder {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-control::placeholder {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-check .form-check-label {
    font-size: 15px;
  }

  .contact-form-content .form-group .form-check .form-check-label a {
    font-size: 15px;
  }

  .blog-right-hover-content {
    margin-left: 0;
  }

  .blog-hover-content ul li .single-hover-content ul li {
    font-size: 15px;
    padding-left: 25px;
  }

  .blog-hover-content ul li .single-hover-content ul li img {
    max-width: 15px;
  }

  .blog-hover-content ul li .single-hover-content ul li a {
    font-size: 14px;
  }

  .blog-hover-content ul li .single-hover-content h3 {
    font-size: 18px;
  }

  .blog-hover-content ul li .single-hover-content .read-more {
    font-size: 15px;
  }

  .blog-hover-content ul li .single-hover-content .read-more i {
    font-size: 15px;
  }

  .footer-subscribe {
    margin-left: -40px;
  }

  .default-btn {
    font-size: 13px;
    padding: 12.5px 15px;
  }

  .default-btn i {
    font-size: 13px;
    margin-left: 3px;
    top: 2.5px;
  }

  .single-banner-three-content h1 {
    font-size: 60px;
    line-height: 80px;
    margin-bottom: 10px;
  }

  .conference-schedules-tab .nav.nav-tabs {
    margin-bottom: 0;
  }

  .conference-schedules-tab .nav.nav-tabs .nav-item .nav-link {
    padding: 18px 66.3px !important;
  }

  .blog-post-card .blog-post-text {
    padding: 20px;
  }

  .blog-post-card .blog-post-text ul {
    margin-bottom: 20px;
  }

  .blog-post-card .blog-post-text ul li {
    margin-right: 15px;
    font-size: 12px;
    padding-left: 20px;
  }

  .blog-post-card .blog-post-text ul li img {
    max-width: 15px;
  }

  .blog-post-card .blog-post-text ul li span {
    padding: 7px 10px;
    font-size: 12px;
  }

  .blog-post-card .blog-post-text ul li a {
    font-size: 12px;
  }

  .blog-post-card .blog-post-text h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .blog-post-right-bar {
    padding-left: 10px;
  }

  .single-blog-details-content h2 {
    font-size: 26px;
  }

  .recent-posts-card .recent-posts-item .recent-text p {
    margin-bottom: 10px;
  }

  .single-blog-details-content .event-details-card {
    padding: 30px 30px 30px 123px;
  }

  .single-blog-details-content .event-details-card img {
    left: 40px;
  }

  .single-blog-details-content .blog-details-comment p {
    font-size: 15px;
  }

  .single-blog-details-content h3 {
    font-size: 25px;
  }

  .single-blog-details-content .leave-comment h3 {
    font-size: 25px;
  }

  .popular-tags ul li a {
    padding: 10px 15px;
    font-size: 13px;
  }

  .conference-schedules-content .single-schedules-item .schedules-text span {
    margin-bottom: 15px;
  }

  .conference-schedules-content .single-schedules-item .schedules-text .client-item {
    padding-left: 0;
  }

  .conference-schedules-content .single-schedules-item .schedules-text .client-item img {
    position: unset;
    transform: translateY(0);
    margin-bottom: 5px;
  }

  .conference-schedules-content .schedules-btn .default-btn {
    padding: 15.5px 25px;
  }

  .why-the-conference-content .section-title h2 {
    font-size: 35px;
  }

  .blog-hover-content ul li .single-hover-content {
    padding: 15.4px;
  }

  .single-blog-left-content .section-title h2 {
    font-size: 34px;
  }

  .banner-three-area .container-fluid {
    max-width: 1140px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .why-the-conference-content.about-conference-content .section-title {
    margin-bottom: 20px;
  }

  .why-the-conference-content.about-conference-content .section-title h2 {
    font-size: 30px;
  }

  .main-nav.main-style-two .container-fluid {
    max-width: 1140px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-nav.main-style-two nav {
    padding-top: 20px !important;
  }

  .testimonials-card {
    padding: 15px;
  }

  .testimonials-card .testimonials-cliend h3 {
    font-size: 17px;
  }

  .testimonials-card .testimonials-icon {
    top: 10px;
    right: 15px;
  }

  .single-events-item .events-content {
    padding: 0px 8px 0px 8px;
  }

  .single-events-item .events-content h3 {
    margin-bottom: 10px;
  }

  .single-events-item .events-content p {
    margin-bottom: 10px;
  }

  .single-banner-three-content .banner-input-fill .default-btn {
    padding: 14.5px 15px;
    font-size: 13px;
    margin-left: -12px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control {
    padding: 0px 50px 0 0;
    font-size: 15px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control::-moz-placeholder {
    font-size: 15px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control:-ms-input-placeholder {
    font-size: 15px;
  }

  .single-banner-three-content .banner-input-fill .form-group .form-control::placeholder {
    font-size: 15px;
  }

  .single-banner-three-content .banner-input-fill .form-group::after {
    right: 28px;
  }

  .single-banner-three-content .banner-input-fill .banner-select {
    margin-left: -20px;
  }

  .single-conference-content .section-title h2 {
    font-size: 35px;
  }

  .single-conference-content .conference-item {
    padding-left: 64px;
  }

  .single-conference-content .conference-item h3 {
    font-size: 18px;
  }

  .single-conference-content .conference-item .conference-icon {
    width: 50px;
    height: 50px;
    line-height: 55px;
    font-size: 24px;
  }

  .conference-style-three-img .conference-style-3 {
    left: 45px;
  }

  .schedules-style-three-content .schedules-style-list ul {
    margin-right: -20px;
  }

  .schedules-style-three-content .schedules-speakers-img ul {
    margin-left: -20px;
  }

  .event-photo-slider.owl-theme .owl-nav {
    margin-top: 30px;
  }

  .event-photo-slider.owl-theme .owl-nav [class*=owl-] {
    position: unset;
    transform: translateY(0);
  }

  .single-faqs-content {
    padding-left: 0;
  }

  .blog-style-three-area .container-fluid {
    left: calc((100% - 1140px) / 2);
  }

  .blog-style-three-area .section-title h2 {
    font-size: 30px;
  }

  .blog-style-three-slider.owl-theme .owl-nav {
    bottom: 65px;
  }

  .submenu-area .container-fluid {
    max-width: 1140px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-slider-two-item .container-fluid {
    max-width: 1140px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero-content-tow h1 {
    font-size: 80px;
    line-height: 85px;
    margin-bottom: 20px;
  }

  .hero-content-tow .upcoming-list {
    margin-bottom: 50px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 span {
    font-size: 30px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 p {
    font-size: 12px;
  }

  .join-conference-content .section-title {
    margin-bottom: 20px;
  }

  .join-conference-content .section-title h2 {
    margin-bottom: 10px;
    font-size: 35px;
  }

  .join-conference-image .conference-experience::after {
    width: 100%;
  }

  .schedules-style-two-tabs .nav.nav-pills .nav-item .nav-link {
    padding: 18px 60px;
  }

  .schedules-style-two-content {
    padding: 25px;
  }

  .schedules-style-two-content .digital-schedules-item p {
    margin-bottom: 15px;
    font-size: 15px;
  }

  .schedules-style-two-content .digital-schedules-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client {
    padding-left: 58px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers {
    padding-left: 105px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-bg-img-two {
    left: 50px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-text p {
    font-size: 14px;
  }

  .schedules-style-two-content .schedules-style-list ul li {
    font-size: 15px;
  }

  .single-plans-table {
    padding: 22px;
  }

  .single-plans-table .plans-header .price {
    font-size: 35px;
  }

  .why-the-conference-content.conference-style-two-content .section-title {
    margin-bottom: 15px;
  }

  .why-the-conference-content.conference-style-two-content .section-title h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }

  .why-the-conference-content ul {
    margin-bottom: 20px;
  }

  .single-blog-style-two-card .blog-style-two-content h3 {
    margin-top: 25px;
  }

  .single-blog-style-two-card .blog-style-two-content .date {
    right: 20px;
    max-width: 60px;
    padding: 7px;
    top: -74px;
  }

  .single-blog-style-two-card .blog-style-two-content .date span {
    font-size: 13px;
  }

  .single-blog-style-two-card .blog-style-two-content .date p {
    font-size: 13px;
  }

  .subscribed-form-item .section-title h2 {
    font-size: 34px;
  }

  .subscribed-form-item .subscribed-form .newsletter-form .form-control {
    padding: 15px 30px;
  }

  .footer-area.footer-style-two .footer-widget.footer-widget-link {
    padding-left: 35px;
  }

  .footer-area.footer-style-two .footer-widget.services-widget {
    padding-left: 55px;
  }

  /*thulasi*/
  .hero-content h1{
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 15px;
  }

  .conference-item h3{
    padding-top: 5px;
   }

   .footer-subscribe i{
  padding-right: 5px;
}
.footer-subscribe ul{
  list-style-type: none;
  padding-left: 0;
}
.footer-subscribe a{
  text-decoration: none;
  color: black;
}
.footer-subscribe li a{
  position: relative;
  padding-left: 28px;
  display: inline-block;
}
.footer-subscribe a i{
  position: absolute;
  left:0;
  top:4px;
}
.footer-subscribe li{
  padding-bottom: 20px;
}
   /*thulasi*/
}

@media only screen and (min-width: 1399px) and (max-width: 1599px) {
  .single-banner-three-content h1 {
    font-size: 75px;
    line-height: 90px;
  }

  .single-banner-three-content .banner-input-fill .form-group .banner-select i {
    right: 0;
  }

  .single-banner-three-content .banner-input-fill .default-btn {
    font-size: 13px;
    padding: 16.5px 19px;
  }

  .single-banner-three-content .banner-input-fill .default-btn i {
    font-size: 13px;
  }

  .event-photo-slider.owl-theme .owl-nav [class*=owl-] {
    font-size: 20px;
    width: 35px;
    height: 35px;
    line-height: 38px;
    left: -48px;
  }

  .event-photo-slider.owl-theme .owl-nav .owl-next {
    left: auto;
    right: -48px;
  }

  .hero-content-tow h1 {
    font-size: 98px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape {
    top: -2px;
  }

  .join-conference-content .section-title h2 {
    font-size: 35px;
  }

  .contact-form-content .section-title h2 {
    font-size: 39px;
  }

  .blog-post-card .blog-post-text {
    padding: 20px;
  }

  .blog-post-card .blog-post-text ul {
    margin-bottom: 20px;
  }

  .blog-post-card .blog-post-text ul li {
    margin-right: 10px;
    font-size: 14px;
    padding-left: 20px;
  }

  .blog-post-card .blog-post-text ul li img {
    max-width: 15px;
  }

  .blog-post-card .blog-post-text ul li span {
    padding: 7px 10px;
    font-size: 14px;
  }

  .blog-post-card .blog-post-text ul li a {
    font-size: 14px;
  }

  .blog-post-card .blog-post-text h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .popular-tags ul li a {
    font-size: 13px;
  }

  .single-blog-details-content h2 {
    font-size: 28px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers {
    padding-left: 100px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-bg-img-two {
    left: 48px;
  }

  .schedules-style-two-content .digital-schedules-item .client-speakers .client-text p {
    font-size: 14px;
  }

  .schedules-style-two-content .digital-schedules-item .schedules-client {
    padding-left: 60px;
  }

  .single-blog-style-two-card .blog-style-two-content .date {
    top: -80px;
  }

  .blog-style-three-slider.owl-theme .owl-nav {
    bottom: 75px;
  }

  .single-plans-table {
    padding: 20px;
  }

  .single-conference-content .section-title h2 {
    font-size: 35px;
    font-weight: bold;
  }

  .single-conference-content .conference-item {
    padding-left: 80px;
  }

  .single-conference-content .conference-item h3 {
    font-size: 18px;
  }

  .single-conference-content .conference-item p {
    font-size: 14px;
  }

  .conference-schedules-tab .nav.nav-tabs {
    margin-bottom: 0;
  }

  .conference-schedules-tab .nav.nav-tabs .nav-item .nav-link {
    padding: 18px 65.9px !important;
  }

  .conference-schedules-content {
    padding: 20px;
  }

  .conference-schedules-content .single-schedules-item .schedules-text span {
    margin-bottom: 15px;
  }

  .conference-schedules-content .single-schedules-item .schedules-text .client-item {
    padding-left: 0;
  }

  .conference-schedules-content .single-schedules-item .schedules-text .client-item img {
    position: unset;
    transform: translateY(0);
    margin-bottom: 5px;
  }

  .conference-schedules-content .schedules-btn .default-btn {
    padding: 15px;
  }

  .why-the-conference-content .section-title h2 {
    font-size: 35px;
  }

  .blog-hover-content ul li .single-hover-content {
    padding: 15px;
  }

  .single-events-item .events-content {
    padding: 0px 10px 0px 8px;
  }

  .single-blog-left-content .section-title h2 {
    font-size: 34px;
  }

  .subscribed-form-item {
    padding: 60px;
  }

  .subscribed-form-item .section-title h2 {
    font-size: 36px;
  }

  .team-card.team-card-three .team-three-text {
    padding: 20px;
  }

  .testimonials-card {
    padding: 18px;
  }

  .testimonials-card .testimonials-cliend h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .testimonials-card p {
    font-size: 15px;
  }

  .testimonials-card .testimonials-icon {
    top: 15px;
    right: 23px;
  }

  .footer-area.footer-style-two .footer-widget.services-widget {
    padding-left: 50px;
  }

  .footer-subscribe {
    margin-left: -28px;
  }

  .footer-subscribe h2 {
    font-size: 17px;
  }

  .schedules-style-three-content .schedules-style-list ul {
    margin-right: -20px;
  }

  .schedules-style-three-content .schedules-speakers-img ul {
    margin-left: -20px;
  }

  .blog-style-three-area .section-title h2 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 1600px) {
  .submenu-area .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-nav .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-nav.main-style-two .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner-three-area .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hero-slider-item .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hero-area-two .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .blog-hover-content ul li .single-hover-content {
    padding: 15px 27px;
  }

  .single-banner-three-content .banner-input-fill .form-group .banner-select img {
    right: 50px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 span {
    font-size: 50px;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown .countdown-content-10 .live-auctions-countdown-shape {
    top: 0;
  }

  .hero-content-tow .upcoming-list .live-auctions-countdown li {
    width: 160px;
    height: 132px;
  }

  .blog-style-three-slider.owl-theme .owl-nav {
    bottom: 20%;
  }

  .blog-style-three-area .section-title h2 {
    font-size: 40px;
  }

  .single-blog-style-three-card .blog-style-two-content {
    padding: 35px;
  }

  .single-blog-style-three-card .blog-style-two-content h3 {
    font-size: 20px;
  }

  .join-conference-image .conference-experience::after {
    width: 100%;
  }

  .single-banner-three-content h1 {
    font-size: 95px;
  }

  .single-banner-three-content .banner-input-fill {
    padding: 15px 25px;
  }

  .single-banner-three-content .banner-input-fill .default-btn {
    padding: 17.5px 26px;
    font-size: 15px;
  }

  .single-banner-three-content .banner-input-fill .form-group::after {
    height: 45px;
    right: 25px;
    top: -10px;
  }

  .single-banner-three-content .banner-input-fill .form-group .banner-select img {
    right: 0;
    left: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .page-banner-area .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .page-banner-area .page-banner-shape-3 {
    top: 15%;
    left: 4%;
    max-width: unset;
  }

  .page-banner-area .page-banner-shape-2 {
    top: 25%;
    right: 6%;
    max-width: unset;
  }
}

@media only screen and (max-width: 991px) {
  .mobile-nav {
    display: block;
    position: relative;
  }

  .others-option-for-responsive {
    display: block !important;
    position: relative;
  }

  .mobile-nav .logo {
    text-decoration: none;
    position: absolute;
    top: 0;
    z-index: 999;
    left: 0;
    color: #2A07F9;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
  }

  .mean-container .mean-bar {
    background-color: transparent;
    padding: 0;
    border-bottom: none;
  }

  .mean-container a.meanmenu-reveal {
    padding: 12px 0 0 0;
    color: #ec642b;
    top: -32px;
  }

  .mobile-nav nav .navbar-nav .nav-item a i {
    display: none;
  }

  .mean-container a.meanmenu-reveal span {
    background: #ec642b;
    height: 4px;
    border-radius: 3px;
    position: relative;
    top: 8px;
  }
}

/*# sourceMappingURL=responsive.css.map */